import React, { useState } from "react";
import { PopupButton } from "@typeform/embed-react";

import { Title } from "components";
import styles from "./contact.module.scss";

export const Contact: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checked, setChecked] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleCheckbox = () => setChecked((wasChecked) => !wasChecked);

  const root = document.getElementById("root");
};
