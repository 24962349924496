import React from "react";
import { createRoot } from "react-dom/client";

import { Providers } from "components";
import { App } from "app";
import { reportWebVitals } from "reportWebVitals";

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
);

reportWebVitals();
