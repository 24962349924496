import React from "react";

import styles from "./hamburger.module.scss";

interface Props {
  isOpen: boolean;
  toggleMenu: () => void;
}

export const Hamburger: React.FC<Props> = ({ isOpen, toggleMenu }) => {
  return (
    <button type="button" className={styles.outer} onClick={toggleMenu}>
      <div className={isOpen ? styles.innerOpen : styles.innerClose} />
    </button>
  );
};
