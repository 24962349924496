import React from "react";

import { Header } from "components";

import background from "assets/images/background.jpeg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

import styles from "./banner.module.scss";

interface Props {
  navigateTo?: string;
}

export const Banner: React.FC<Props> = ({ navigateTo }) => {
  const navigateToSection = () => {
    window.location.href = navigateTo || "#what-we-do";
  };

  return (
    <div className={styles.container}>
      <img src={background} alt="Planet" className={styles.background} />
      <div className={styles.overlay} />
      <div className={styles.content}>
        <Header />
        <section className={styles.innerContent}>
          <h2 className={styles.title}>
            Data <br />
            <span>Security </span>
            and Confidentiality
          </h2>
          <p className={styles.description}>
            Vaultchain is the DLT software platform securing data processing and data validation for insurance and public sector.
            We secure data, build efficient Data Governance and build trust in data.
          </p>

          <button type="button" className={styles.button} onClick={navigateToSection}>
            Secure now
            <Arrow />
          </button>
        </section>
      </div>
    </div>
  );
};
