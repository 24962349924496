import React from "react";

import { Title, EnterTitle } from "components";

import styles from "./what-we-do.module.scss";

export const WhatWeDo: React.FC = () => {
  return (
    <div className={styles.container} id="what-we-do">
      <section className={styles.content}>
        <Title>What we do</Title>
        <p className={styles.text}>
          Vision: Protecting data confidentiality to be compliant with all regulations.
          <br />
          <br />
          Scalable decentralized platform to bring trust in any place of the network.
        </p>
        <EnterTitle section="#contact">Contact us</EnterTitle>
      </section>
      <section className={styles.details} />
    </div>
  );
};
