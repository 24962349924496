import React from "react";

import { Banner } from "./banner/banner";
import { WhatWeDo } from "./what-we-do/what-we-do";
import { Contact } from "./contact/contact";
import { Footer } from "./footer/footer";

import styles from "./landing.module.scss";

export const LandingPage: React.FC = () => {
  return (
    <div>
      <Banner />
      <WhatWeDo />
      <div className={styles.footerContainer}>
        <Contact />
        <Footer />
      </div>
    </div>
  );
};
