import React from "react";

import { MobileMenuProps } from "./mobile-menu.types";
import { menuItems } from "./mobile-menu.constants";

import logo from "assets/images/logo.png";

import styles from "./mobile-menu.module.scss";

export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, closeMenu }) => {
  return (
    <div className={isOpen ? styles.open : styles.close}>
      <img src={logo} alt="Magellanic logo" className={styles.logo} />
      <section className={styles.content}>
        {menuItems.map(({ name, path }) => (
          <a key={name} href={path} className={styles.item} onClick={closeMenu}>
            {name}
          </a>
        ))}
      </section>
    </div>
  );
};
