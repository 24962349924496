import React from "react";

import { Title } from "components";

import logo from "assets/images/logo.png";

import styles from "./footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <footer className={styles.container}>
      <Title containerClassName={styles.titleContainer} className={styles.title}>
        Contact
      </Title>
      <div className={styles.content}>
        <div>
        <h6 className={styles.subtitle}>Vaultchain Sp. z o.o.</h6>
          <p className={styles.text}>
            NIP 6751778097<br />
            Aleja Powstania Warszawskiego 15, 31-539 Kraków<br />
            Poland
          </p>
        </div>

        <div>
          <br />
          <a href="mailto: contact@vaultchain.pl" className={styles.text}>
            contact@vaultchain.pl
          </a>
        </div>
        <img src={logo} alt="Vaultchain logo" className={styles.logo} />
      </div>
    </footer>
  );
};
